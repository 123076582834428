<template>
  <v-container fluid>
    <v-form @submit.prevent="submitSearch" :disabled="isFetching || tableLoading">
      <v-row>
        <v-col cols="12">
          <v-btn
            depressed
            color="primary accent-4"
            class="primary m-auto white--text"
            :to="{ name: 'adminClaimCreate' }"
            :disabled="isFetching || tableLoading"
            >新增報銷申請</v-btn
          >
        </v-col>

        <v-col cols="12" md="4" lg="3" xl="3">
          <FormControl
            inputType="select"
            :inputValue.sync="search.year"
            label="年份"
            :options="yearList"
            :dense="true"
            @selectChange="yearChange"
          />
        </v-col>
        <v-col cols="12" md="4" lg="3" xl="3">
          <FormControl
            inputType="select"
            :inputValue.sync="search.month"
            label="月份"
            :options="monthList"
            :dense="true"
          />
        </v-col>
        <v-col cols="12" md="4" lg="3" xl="3">
          <FormControl
            inputType="select"
            :inputValue.sync="search.staff"
            label="職員"
            :options="staffList"
            :dense="true"
          />
        </v-col>
        <v-col md="12" cols="12" lg="3" xl="3" class="d-flex align-center">
          <v-btn
            depressed
            color="success darken-4"
            class="mr-6 white--text"
            type="submit"
            :loading="isFetching || tableLoading"
            small
            >搜尋</v-btn
          >
          <v-btn depressed color="error" @click.prevent="clearSearch" :disabled="isFetching || tableLoading" small
            >清除</v-btn
          >
        </v-col>
      </v-row>

      <!-- <v-col cols="12" md="2" class="pa-2 align-center justify-end d-flex" flat outlined tile >
                  <div class="">
                     <v-dialog  max-width="400" >
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn depressed 
                              v-on="on" 
                              v-bind="attrs" 
                              small 
                              color="secondary" 
                              class="m2 white--text" 
                              :loading="isFetching" 
                              :disabled="isFetching">
                              <v-icon left color="white">{{ icons.mdiDownloadOutline }}</v-icon>下載
                           </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                           <v-card class="py-3">
                              <v-card-text>
                                 <div class="text-h6 pa-2">
                                    <FormControl inputType="select" :inputValue.sync="download.date" placeHolder="日期" />
                                 </div>
                              </v-card-text>
                              <v-card-actions class="justify-center">
                                 <v-btn depressed 
                                    color="primary accent-4" 
                                    class="primary m-auto white--text" 
                                    block 
                                    @click="dialog.value=false">下載</v-btn>
                              </v-card-actions>
                           </v-card>
                        </template>
                     </v-dialog>
                  </div>
               </v-col> -->
    </v-form>

    <v-row class="mt-6">
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'adminClaimDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import Datatable from '@/components/Datatable.vue'
import { mdiDownloadOutline } from '@mdi/js'

export default {
  name: 'AdminClaim',
  components: {
    Datatable,
    FormControl,
  },
  data() {
    return {
      isFetching: false,
      tableLoading: false,
      icons: {
        mdiDownloadOutline,
      },
      tableHeaders: [
        { text: '提交日期', value: 'create_date' },
        { text: '員工', value: 'staff' },
        { text: '報銷項目', value: 'name' },
        { text: '報銷日期', value: 'date' },
        { text: '報銷類型', value: 'claim_type' },
        { text: '金額', value: 'amount' },
        { text: '申請狀態', value: 'approve_status' },
        {
          text: '',
          value: 'actionView',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
      search: {
        year: '',
        month: '',
        staff: '',
      },
      yearList: [],
      monthList: [],
      yearMonthList: [],
      staffList: [],
      download: {
        date: '',
      },
    }
  },
  methods: {
    async getFilterYearMonthList() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_distinct_claim_year_month_list', payload)
        this.$func.log('-----Get Year Month List-----')
        this.$func.log(result)

        this.yearMonthList = result
        this.yearList = result.map(obj => {
          return obj.year
        })
        this.monthList = []
      } catch (error) {
        this.$func.log('-----Get Year Month List fail-----')
        this.$func.log(error)

        this.yearList = []
        this.monthList = []
        this.yearMonthList = []
      }
    },
    async getStaffData() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          const data = {
            value: resultData.id,
            text: `${resultData.name} (${resultData.email})`,
          }
          temp.push(data)
        }

        this.staffList = temp
      } catch (error) {
        this.$func.log('-----Get Staff fail-----')
        this.$func.log(error)
      }
    },
    async getClaimData() {
      if (!this.tableLoading && !this.isFetching) {
        this.tableLoading = true
        this.isFetching = true

        try {
          const payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            verify_token: this.getAdminToken(),
            join_staff: true,
          }

          if (this.$validate.DataValid(this.search.staff)) {
            payload.staff_id = parseInt(this.search.staff)
          }

          if (this.$validate.DataValid(this.search.year)) {
            let month = ''
            if (this.$validate.DataValid(this.search.month)) {
              month = '-' + this.search.month
            }

            payload.filter_year_month = this.search.year + month
          }

          const result = await this.$XHR.api('cms_get_claim_list', payload)
          this.$func.log('-----Get Claim List-----')
          this.$func.log(result)

          this.formItemTotal = result.total

          const temp = []

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            const data = {
              id: resultData.id,
              create_date: resultData.create_date,
              staff: '',
              name: resultData.name,
              date: resultData.date,
              claim_type: resultData.claim_type,
              approve_status: resultData.approve_status,
              amount: `$${resultData.amount}`,
            }

            if (this.$validate.DataValid(resultData.staff_data)) {
              data.staff = `${resultData.staff_data.name} (${resultData.staff_data.email})`
            }

            temp.push(data)
          }

          this.formData = temp
        } catch (error) {
          this.$func.log('-----Get Claim List fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'no permission') {
            msg = '沒有權限'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.formData = []
          this.formItemTotal = 0
        } finally {
          this.tableLoading = false
          this.isFetching = false
        }
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getClaimData()
    },
    yearChange() {
      if (this.search.year) {
        const filterYear = this.yearMonthList.filter(obj => {
          return obj.year === this.search.year
        })

        if (filterYear.length > 0) {
          this.monthList = filterYear[0].month
        }
      } else {
        this.monthList = []
      }
    },
    submitSearch() {
      if (
        this.$validate.DataValid(this.search.year) ||
        this.$validate.DataValid(this.search.month) ||
        this.$validate.DataValid(this.search.staff)
      ) {
        this.formItemTotal = 0
        this.tablePage = 1
        this.formData = []
        this.getClaimData()
      }
    },
    clearSearch() {
      this.formItemTotal = 0
      this.tablePage = 1
      this.formData = []
      this.search = {
        year: '',
        month: '',
        staff: '',
      }
      this.getClaimData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getFilterYearMonthList()
      this.getStaffData()
      this.getClaimData()
    }
  },
}
</script>